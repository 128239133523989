<template>
    <div class="home_box">
        
        <div class="h_table">
            <div class="h_table_top">
                <div class="t_top_1">
                    <!-- <el-button class="order_btn blue_bgc" size="small" icon="el-icon-plus" @click="add_candidate_dlg=true">添加候选人</el-button> -->
                </div>
                <div class="t_top_Right">
                     <div class="t_top_3">
                        <el-input :placeholder="$t('main.text98')" v-model="form.search_content"></el-input>
                        <i class="el-icon-search"></i>
                     </div>
                     <div>
                        <el-button plain class="search_btn" @click="search">{{$t('main.text99')}}</el-button>
                        <el-button plain class="reset_btn" @click="reset">{{$t('main.text13')}}</el-button>
                     </div>
                </div>
            </div>
            <el-table
            :data="tableData"
            :cell-style="{textAlign:'center',color:'#333'}"
            :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            style="width: 100%">
                <el-table-column :label="$t('main.text14')"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('main.text87')">
                    <span slot-scope="props">
                        <div v-show="!props.row.is_edit">{{ props.row.name }}</div>
                        <div v-show="props.row.is_edit">
                            <el-input class="form_my_inp" v-model="props.row.name"></el-input>
                        </div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text88')">
                    <span slot-scope="props">
                        <div v-show="!props.row.is_edit">{{ props.row.mobile }}</div>
                        <div v-show="props.row.is_edit">
                            <el-input class="form_my_inp" v-model="props.row.mobile"></el-input>
                        </div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text89')">
                    <span slot-scope="props">
                        <div v-show="!props.row.is_edit">{{ props.row.email }}</div>
                        <div v-show="props.row.is_edit">
                            <el-input class="form_my_inp" v-model="props.row.email"></el-input>
                        </div>
                    </span>
                </el-table-column>                
                <el-table-column :label="$t('main.text100')" min-width="140">
                    <span slot-scope="props">
                        <span class="details" v-show="!props.row.is_edit" @click="to_candidate_info(props.row.userid)">{{$t('main.text101')}}</span>
                        <span v-show="!props.row.is_edit"> | </span>
                        <span class="details" v-show="!props.row.is_edit" @click="props.row.is_edit=true">{{$t('main.text24')}}</span>
                        <span class="details" v-show="props.row.is_edit">
                            <span @click="edit_complete(props.row)">{{$t('main.text25')}}</span>
                            <span> | </span>
                            <span @click="reset">{{$t('main.text26')}}</span>
                        </span>
                        <!-- <span> | </span>
                        <span class="details" @click="open_delete_dlg(props.row.userid)">删除</span> -->
                    </span>
                </el-table-column>

            </el-table>

            <el-pagination
                class="pagination_box"
                v-if="total"
                background
                @size-change="size_change"
                @current-change="current_change"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

        </div>

        <!-- 添加候选人 弹框 -->
        <el-dialog
            title="$t('main.text102')"
            :visible.sync="add_candidate_dlg"
            width="600px"
            top="20%"
            center>
            <div class="dlg_main">
                <el-form label-position="right" label-width="auto">
                    <el-form-item :label="$t('main.text226')">
                        <el-input v-model.trim="add_form.name" :placeholder="$t('main.text240')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('main.text88')">
                        <el-input v-model.trim="add_form.mobile" :placeholder="$t('main.text104')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('main.text89')">
                        <el-input v-model.trim="add_form.email" :placeholder="$t('main.text105')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('main.text106')">
                        <el-input v-model.trim="add_form.job" :placeholder="$t('main.text107')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('main.text92')">
                        <el-select v-model="add_form.status" :placeholder="$t('main.text108')">
                            <el-option label="$t('main.text109')" value="1"></el-option>
                            <el-option label="$t('main.text110')" value="5"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="add_candidate_dlg_confirm" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="add_candidate_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

        <!-- 删除 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="delete_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">{{$t('main.text111')}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="delete_dlg_confirm" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="delete_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
    
<script>
import {candidates_list,candidates_create,candidates_delete,candidates_edit} from '@/api/api';
export default{
    data() {
        return {
            currentPage: 1, //选中的页数
            page: 1, //分页 从 1 开始
            limit: 10, //每页条数
            total: 0, //一共多少条
            tableData: [],
            form:{
                search_content:"",
            },
            add_candidate_dlg:false,
            add_form:{
                name:"",
                mobile:"",
                email:"",
                job:"",
                status:"",
            },
            delete_dlg:false,
            // work_order_checked:false,
            userid:'',
            new_work_data:[],
        }
    },
    mounted() {
        this.get_list();
    },
    methods: {
        current_change(v) {
            this.page = v;
            this.limit = 10;
            this.get_list();
        },
        size_change(v) {
            this.limit = v;
            this.get_list();
        },
        async get_list(){
            const res = await candidates_list({
                page:this.page,
                limit:this.limit,
                search_content:this.form.search_content,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            // this.tableData = res.data.data.list;
            let d = res.data.data.list;
            for (const item of d) {
                if(! ("is_edit" in item)){
                    item.is_edit = false;
                }
            }
            this.tableData = d;
            this.new_work_data=[];
            this.total = res.data.data.countAll;
        },
        search(){
            this.page=1;
            this.limit=10;
            this.currentPage = 1;
            this.get_list();
        },
        reset(){
            this.page=1;
            this.limit=10;
            this.currentPage = 1;
            this.form.search_content="";
            this.get_list();
        },
        to_candidate_info(userid){
            this.$router.push({path:'/candidate_info',query:{userid:userid}});
        },
        async add_candidate_dlg_confirm(){
            if(!this.add_form.name){
                return this.msg("error",this.$t('main.text103'));
            }
            if(!this.add_form.mobile){
                return this.msg("error",this.$t('main.text104'));
            }
            if(!this.add_form.email){
                return this.msg("error",this.$t('main.text105'));
            }
            if(!this.add_form.job){
                return this.msg("error",this.$t('main.text107'));
            }
            if(!this.add_form.status){
                return this.msg("error",this.$t('main.text108'));
            }
            const res = await candidates_create({
                email:this.add_form.email,
                mobile:this.add_form.mobile,
                name:this.add_form.name,
                job:this.add_form.job,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text112'));
            this.add_candidate_dlg=false;
            this.get_list();
        },
        open_delete_dlg(userid){
            this.userid=userid;
            this.delete_dlg=true;
        },
        async delete_dlg_confirm(){
            const res = await candidates_delete({
                userid:this.userid,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text113'));
            this.delete_dlg=false;
            this.get_list();
        },
        async edit_complete(row){
            if(
                !row.email||
                !row.mobile||
                !row.name||
                !row.job||
                !row.status
            ){
                return false;
            }
            const res = await candidates_edit({
                userid:row.userid,
                email:row.email,
                mobile:row.mobile,
                name:row.name,
                job:row.job,
                status:row.status,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text114'));
            this.get_list();
        },
        



    },
    watch:{
        add_candidate_dlg(){
            if(this.add_candidate_dlg){
                this.add_form={
                    name:"",
                    mobile:"",
                    email:"",
                    job:"",
                    status:"",
                };
            }
        }
    }

}
</script>

<style scoped>
.h_table{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}
.h_table_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
}
.t_top_Right>div{
    display: inline-block;
}
.t_top_2{
    max-width: 160px;
    margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner{
    border-color: #A9A9A9;
    color: #BCBCBC;
}
.t_top_3{
    max-width: 200px;
    margin-right: 30px;
    position: relative;
}
.t_top_3 i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #BCBCBC;
    font-size: 20px;
}
.t_top_3 >>> .el-input__inner{
    text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
    border-color: #409EFF;
}
.dlg_main{
    margin: auto;
    width: 350px;
}
.dlg_main >>> .el-form-item__content {
    max-width: 280px;
}
.dlg_main >>> .el-form-item__label{
    color: #838383;
}
.dlg_main >>> .el-select {
    width: 100%;
}

</style>
